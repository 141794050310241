/* eslint-disable max-len */
import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Route, Redirect, Switch,
} from 'react-router-dom'
import PerimetersService from 'reducers/services/perimetersService'
import Loader from 'components/Common/Loader'
import { attemptLoginOnLaunch } from '@osrdata/app_core/dist/auth/redux/index'
import { RootState } from 'Store'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { getFullscreenHandler } from 'reducers/app'
import {
  hasAdminAccess, hasOperatorAccess, hasOpentcoAccess, hasVigneuxAccess,
  hasSaintRaphaelAccess,
} from 'helpers/permissions'
import { ThemeProvider } from '@mui/material'
import Cgu from 'components/Cgu/Cgu'
import { theme } from 'common/CustomTheme'
import TopBar from 'components/Common/TopBar/TopBar'
import Home from 'components/Perimeters/Home'
import FullViewMap from 'components/Views/FullViewMap'
import WidgetsViewMap from 'components/Views/WidgetsViewMap'
import CameraView from 'components/Views/CameraView'
import Dashboard from 'components/Zones/Dashboard'
import terms from 'common/terms'
import history from './appHistory'

import 'App.scss'

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { isLogged } = useSelector((state: RootState) => state.user)
  const handle = useFullScreenHandle()
  const [cgu, setCgu] = useState<boolean>(JSON.parse(localStorage.getItem('cguAccepted')) || false)
  const { activePerimeter } = useSelector((state: RootState) => state.perimeter)

  useEffect(() => {
    dispatch(attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    dispatch(getFullscreenHandler(handle))
  }, [handle])

  useEffect(() => {
    if (activePerimeter) {
      dispatch(PerimetersService.getSignalsLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getAdvLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getBvLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getSensCirculationLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getQuaiLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getPrTrigramLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getPrLabelLayerData(activePerimeter?.slug))
      dispatch(PerimetersService.getTrackLabelLayerData(activePerimeter?.slug))
    }
  }, [activePerimeter])

  const handleOpen = () => {
    localStorage.setItem('cguAccepted', JSON.stringify(!cgu))
    setCgu(!cgu)
  }

  const renderApp = () => {
    if (!cgu) return <Cgu handleOpen={handleOpen} isOpen={cgu} />
    return (
      <Router history={history}>
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={Dashboard} exact path={`/${activePerimeter?.slug}/dashboard`} />
          <Route component={FullViewMap} exact path={`/${activePerimeter?.slug}/FullView`} />
          <Route component={CameraView} exact path={`/${activePerimeter?.slug}/camera`} />
          <Route component={WidgetsViewMap} path={`/${activePerimeter?.slug}/:obj`} />
          <Redirect to="/" />
        </Switch>
      </Router>
    )
  }

  return (
    <Suspense fallback={<Loader center />}>
      <FullScreen handle={handle}>
        <ThemeProvider theme={theme}>
          <TopBar appName={terms.Common.appName} />
          {isLogged && (hasAdminAccess() || hasOperatorAccess() || hasOpentcoAccess() || hasVigneuxAccess() || hasSaintRaphaelAccess()) && (
            <div id="app">{renderApp()}</div>
          )}
        </ThemeProvider>
      </FullScreen>
    </Suspense>
  )
}
