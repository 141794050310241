import { ReactElement, useState, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '@osrdata/app_core/dist/auth/redux/index'
import { PORTAL_URL } from 'config/config'
import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'
import { RootState } from 'Store'
import terms from 'common/terms'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FaPowerOff } from 'react-icons/fa'
import {
  Button, Menu, MenuItem, Typography, Stack,
} from '@mui/material'
import NavTabs from './NavTabs'

type Props = {
  appName: string,
  }

export default function TopBar({ appName }: Props): ReactElement {
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.user)
  const { fullscreenHandler } = useSelector((state: RootState) => state.app)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const onLogout = () => {
    dispatch(logout())
  }

  return (
    <Stack
      direction="row"
      className={`mastheader${fullscreenHandler.active ? ' fullscreen' : ''}`}
      sx={{ justifyContent: 'space-between', height: '65px', bgcolor: '#242830' }}
    >
      <Stack direction="row" spacing={3} sx={{ mr: '50px', alignItems: 'center' }}>
        {/* <a href={PORTAL_URL.url}> */}
        <img alt={appName} src={logo} width="70" />
        {/* </a> */}
        <Typography className="topbar-appname">
          {appName}
        </Typography>
        <NavTabs />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Button
          onClick={handleButtonClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            textTransform: 'none', color: 'white', fontFamily: 'Avenir', fontSize: '17px',
          }}
        >
          <i className="icons-menu-account icons-size-1x25 icons-md-size-1x5 mr-xl-2 mr-2" aria-hidden="true" />
          {`${user.account.firstName} ${user.account.lastName}`}
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={() => handleMenuClose()}>
          <MenuItem onClick={() => onLogout()}>
            <div className="menu-item-title">
              <span className="mr-2"><FaPowerOff /></span>
              {terms.TopBar.disconnect}
            </div>
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  )
}
